.project-btn-container {
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;

    &__button {
        border: solid 2px #222222;
        background-color: #fff;
        cursor: pointer;
        font-size: $normal-font-size;
        font-weight: 600;
        padding: 9px 15px;
        max-width: 22rem;
        width: 100%;
        margin-right: 1rem;
    }

    // Case study and Demo hover color change.
    .dark-pink-btn{
        &:hover {
            background-color: $dark-peach;
            color: #fff;
        }
    }
    .dark-green-btn{
        &:hover {
            background-color: $dark-green;
            color: black;
        }
    }
    .dark-tilt-btn {
        &:hover {
            background-color: $dark-tilt;
            color: #fff;
        }
    }
    .dark-blue-btn {
        &:hover {
            background-color: $dark-blue;
            color: #fff;
        }
    }
}

.modal__close-button{
    margin: 2rem 0;
    background-color: transparent;
    border: 4px solid #cfcfcf;
    color: #000;
    cursor: pointer;
    padding: 0.5rem 2rem;
    font-size: 1.6rem;
    &:hover {
        background-color: #f4f4f4;
    }

    @media (max-width: 960px) {
        max-height: 40px;
    }
}

.modal__code-button{

    &--green {
        border: 4px solid $dark-green;
        background-color: $dark-green;
        color: #fff;
        margin: 0;
        cursor: pointer;
        padding: 1rem 2rem;
        font-size: 2rem;
        &:hover {
            background-color: #f4f4f4;
            color: #000;
        }
    }

    &--pink {
        border: 4px solid $dark-pink;
        background-color: $dark-pink;
        color: #fff;
        margin: 0;
        cursor: pointer;
        padding: 1rem 2rem;
        font-size: 2rem;
        &:hover {
            background-color: #f4f4f4;
            color: #000;
        }
    }

    &--blue {
        border: 2px solid #000;
        background-color: $dark-blue;
        color: #fff;
        margin:  0;
        margin-left: 0.8rem;
        cursor: pointer;
        font-size: 1.4rem;
        min-height: 30px;
        &:hover {
            background-color: #fff;
            color: #000;
        }
    }



}
