// size for pictures - profile
.profile-picture{
    width: 16rem;
    height: 16rem;
    margin:0 $b-margin;
    border-radius: 50%;
    z-index: 10;
}

@media (max-width: $media-big) {
    //ipad size
    .masonry-page-image {
        max-width: 45rem;
        max-height: 34rem;
    }
}

// less than 700px
@media(max-width: $media-small) {
    .masonry-page-image {
        max-width: 35rem;
        max-height: 24rem;
    }
    .profile-picture{
        width: 135px;
    }
}