.header{
    background-color: transparent;
    position: sticky;
    top: 0;
    padding: $m-padding;
}

.nav {
    margin: 0 $m-margin;

    &__list {
        display: flex;
        margin: 0;
        list-style-type: none;
    }

    &__item {
        text-decoration: none;
        color: #fff;
        font-size: $m-size;
        margin: $s-margin;
    }
}