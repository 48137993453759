.tech-container {
    color: #fff;
    margin-top: 2.4rem;
    flex-wrap: wrap;
    max-width: 65rem;
    &__chip {
        font-size: $normal-font-size;
        margin-right: $chip-margin;
        margin-top: 1rem;
        padding: 7px $s-padding;
        border-radius: 29px;
    }

    .dark-blue{
        background-color: $dark-blue;
    }

    .dark-green{
        background-color: $dark-green;
        color:#000;
    }

    .dark-pink{
        background-color: $dark-pink;
    }
    
    .dark-tilt {
        background-color: $dark-tilt;
    }
}