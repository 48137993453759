.contact-parallax {
  z-index: 1;
}

.contact {
  background-color: $black-opacity;
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.28), 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  padding: 8px;
  top: 42.5rem;
  left: 8%;
  &__icon {
    color: #fff;
    font-size: 2rem;
    margin: 8px 0;
    &:hover {
      color: $light-pink;
    }
  }
}
.contact-parallax {
  height: 20rem;
}
@media (max-width: $media-big-landscape) {
    // ipad pro positioning
    .contact {
      top: 42rem;
      left: 4%;
    }
  }

@media (max-width: $media-big) {
  // ipad pro positioning
  .contact {
    top: 37rem;
    left: 4%;
  }
}
//less than 700px
@media (max-width: $media-small) {
.contact {
    flex-direction: row;
    justify-content: flex-end;
    top: 0rem;
    left: 60%;
    &__icon { 
        margin: 0.5rem 1rem;
    }
}
}
@media (min-height: 376px) and (max-height: 500px) and (max-width: 800px) and (orientation: landscape) {
    .contact {
      top: 20rem;
    }
  }
  @media (max-height: 812px) and (min-width: 800px) and (max-width: 813px) and (orientation: landscape) {
    .contact {
      top: 20rem;
    }
  }