$s-size: 1.2rem;
$m-size: 2.5rem;
$b-size: 3rem;
$description-box-size: 45rem;

$s-margin: 1rem;
$m-margin: 1.6rem;
$chip-margin: 0.8rem;
$b-margin: 3.2rem;
$vb-margin: 4.8rem;
$section-margin: 8.8rem;

$s-padding: 1.2rem;
$m-padding: 2rem;
$vb-padding: 6rem;

$media-mobile: 400px;
$media-small: 700px;
$media-medium: 768px;
$media-big: 1024px;
$media-big-landscape: 1367px;

