html {
  font-size: 10px;
  scroll-behavior: smooth;
  overflow: hidden;
  @media(max-width:700px) and (orientation: portrait){
      font-size: 8px;
  };
}

body {
    margin: 0;
    max-width: 2400px;
    font-family: 'Open Sans', 'Kanit', sans-serif, 'Merriweather', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    

  }

h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
  padding: 0;
  line-height: normal;
}