// size for mymy cocktail
.cocktail-image {
    border: 2px solid #000;
    border-radius: 50%;
    max-width: 40rem;
    max-height: 40rem;
    margin-top: 25rem;
    margin-left: -5rem;
}

.green-circle {
    background-color: $light-green;
    border-radius: 50%;
    width: 50rem;
    height: 50rem;
    margin-top: 50rem;
    margin-left: 8rem;
}

@media (min-width: 700px){
    .cocktail-image {
        border: 2px solid #000;
        border-radius: 50%;
        max-width: 40rem;
        max-height: 40rem;
        margin-top: 30rem;
        margin-left: -3rem;
    }
    
    .green-circle {
        background-color: $light-green;
        border-radius: 50%;
        width: 60rem;
        height: 60rem;
        margin-top: 40rem;
        margin-left: 3rem;
    }
}