$landing-typography: 4.8rem;
$description-typography: 2rem;
$big-title: 4rem;
$normal-font-size: 1.6rem;

.bold-typography {
    font-size: $landing-typography;
    font-weight: bold;
    margin: 0;
}

.description-typography {
    font-size: $description-typography;
}

.footer-typography{
    font-size: 1.5rem;
}

.pink-typography {
    color: $dark-pink;
}

.green-typography {
    color: $dark-green;
}

.tilt-typography {
    color: $dark-tilt;
}