.about-section-parallax {
    min-height: 550px;
}
.parallaxLayer {
    &__project{
        &--background{
            z-index: -1;
        }
    }
}

.z-middle {
    z-index: -50;
}

.z-lowest {
    z-index: -100;
}