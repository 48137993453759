.about-section {
    margin-top: 16rem;
    min-height: 55rem;
}

// about section image size and position.
.about-parallax {
    top: 10rem;
    left: 0;
    &__profile-pic {
        top: 20rem;
        left: 40rem;
    }
}

.blue-square {
    width: 65rem;
    height: 40rem;
    background-color: $light-blue;
}

.about {
    // about section description size and position.
    &__text{
        font-size: $description-typography;
        margin-left: 70rem;

        .description-typography{
            margin-top: $m-margin;
            max-width: 45rem;
        }
    }
}

// ipad Pro (1024px) positioning
@media(max-width: $media-big) {
 
    .about-section {
        margin-top: 15rem;
        min-height: 65rem;

    }
    .blue-square {
        width: 40rem;
        height: 50rem;

    }
    .about-parallax {
        top: 15rem;
        left: 0;
        &__profile-pic {
            top: 30rem;
            left: 15rem;
        }
    }
    .about {
        &__text{
            margin-top: 5rem;
            margin-left: 45rem;
            margin-right: 5rem;
        }
    }
}

//ipad(768px) sizing and positioing
@media (max-width: $media-medium) {

    .about{
        flex-direction: column;
        &__text{
            margin-top: 8rem;
            margin-left: 35rem;
            margin-right: 5rem;
        }
    }
    .blue-square {
        width: 100vw;
        height: 30rem;
    }
    .about-parallax {
        top: 15rem;
        left: 0;
        z-index: -1;
        &__profile-pic {
            top: 25rem;
            left: 8rem;
        }
    }
}

// less than 700px
@media(max-width: $media-small) and (orientation: portrait) {
    .about-section {
        margin-top: 22rem;
    }    
    .about-parallax {
        top: 15rem;

        &__profile-pic {
            position: relative;
            top: 22rem;
            left: 60%;
            width: 100px;
            height: 100px;
        }
    }
    .about{
        max-width: 40rem;
        padding: 0 3rem;

        &__text{
            margin: 0;
        }
    }
}
// less than 400px
@media(max-width: $media-mobile) and (orientation: portrait) {
    // .about-section {
    //     margin-top: 2rem;
    // }    
    .about-parallax {

        &__profile-pic {
            position: relative;
            top: 18rem;
            left: 0%;
            width: 100px;
            height: 100px;
        }
    }
    .about{
        max-width: 40rem;
        padding: 0 3rem;

        &__text{
            margin: 0;
            margin-top: 10rem;
        }
    }

    .blue-square {
        width: 65rem;
        height: 40rem;
    }
}


@media (min-height: 376px) and (max-height: 500px) and (max-width: 800px) and (orientation: landscape) {
    .about-section {
        margin-top: 0rem;
    }
    .about-parallax {
        top: 0;
        &__profile-pic {
            top: 9rem;
        }
    }
}

@media (max-height: 812px) and (min-width: 800px) and (max-width: 813px) and (orientation: landscape) {
    .about-section {
        margin-top: 0rem;
    }
    .about-parallax {
        top: 0;
        &__profile-pic {
            top: 9rem;
        }
    }
}