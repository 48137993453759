$dark-landing-background: #373636;
$black-opacity: rgba(0, 0, 0, 0.75);
$shadow: #2d2c2c;

$light-blue: #aac2d4;
$dark-blue: #1a517b;
$blue: #65A5C7;

$light-green: #B0FF96;
$dark-green: #7FE39D;

$pink : #C27472;
$dar-pink: #BD6C99;

$dark-peach: #814848;
$light-peach: #edc6c6;

$light-pink: #ffb8b7;
$light-pink2: #FAC1E3;
$dark-pink: #ae585a;

$tilt: #00C298;
$dark-tilt: #008266;