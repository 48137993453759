.project-section {
  color: #000;

  //article and image controls elements' size and position.
  // It got so long because I am using React-spring Parallax.
  // Parallax makes a wrapper div that has absolute position and it made things hard to control.
  &__right-article {
    display: flex;
    align-items: center;
    margin-bottom: 30rem;
    margin-left: 20%;
  }
  &__right-image {
    position: relative;
    bottom: 18rem;
    left: 0;
  }

  &__left-article {
    display: flex;
    align-items: center;
    margin-bottom: 30rem;

    .project-description-left {
      margin-left: 90rem;
    }
  }
  &__left-image {
    position: relative;
    top: 0;
    .left-image {
      position: relative;
      left: 40rem;
      bottom: 6rem;
    }
  }

// ipadPro landscape components positioning
  @media (max-width: $media-big-landscape) {
    .project-section {
    &__right-article {
      margin-left: 11%;
      }

    &__left-image {
      .left-image {
        left: 15rem;
      }
    }
    &__left-article {
      .project-description-left {
        margin-left: 65rem;
      }
    }
  } 
  }

  //ipad Pro(1024px) sizing and positioing
  @media (max-width: $media-big) {
    .project-section {
      &__article {
        flex-direction: column;
      }
      &__right-article {
        margin-left: 8%;
        }
  
      &__right-image {
        position: relative;
        bottom: 16rem;
        left: 0;
      }
      &__left-image {
        position: relative;
        top: -5rem;
        .left-image {
          position: relative;
          left: 11rem;
          top: -3rem;
        }
      }
      &__left-article {
        .project-description-left {
          margin-left: 56rem;
        }
      }
    }
  }

  //ipad(768px) sizing and positioing
  @media (max-width: $media-medium) {
    .project-section {
      &__right-article {
        flex-direction: column-reverse;
        margin-top: 45rem;
        margin-left: 0;
      }

      &__right-image {
        position: relative;
        top: -40rem;
        left: -25rem;
      }

      
      &__left-image {
        top: -13rem;
        left: -35rem;
        .left-image {
          left: 16rem;
          top: -10rem;
        }
      }
      &__left-article {
        flex-direction: column;
        margin-top: 53rem;
        .project-description-left {
          margin-left: 15rem;
        }
      }
    }
  }

// less than 700px
  @media(max-width: $media-small) {
    .project-section {
      &__right-article {
        margin-top: 23rem;
        margin-bottom: 35rem;

      }
      &__right-image {
        top: -25rem;
        left: -18rem;
      }
  
      &__left-image {
        top: 0rem;
        .left-image {
          left: 18rem;
        }
      }
      &__left-article {
        margin-top: 15rem;
        .project-description-left {
          margin-left: 0rem;
        }
      }
    }
  }
}

.project {
  margin: $b-margin;
  max-width: $description-box-size;

  &__title {
    font-size: $big-title;
    margin-bottom: $m-margin;
  }
}

