.flex--center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex--end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.flex--start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex--space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex--space-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex--vertical-center {
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.flex--column {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.flex--column-start {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}